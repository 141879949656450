import React from "react"
import Layout from "../components/layout"
import style from "./page.module.css"


const AboutPage = ({data}) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
      <Layout>
    <div className={style.content} >
    <h2>{frontmatter.title}</h2>
     <div dangerouslySetInnerHTML={{ __html: html }}/> 
    </div>
    </Layout>
  )
}
export const query = graphql`
query MyQuery {
  markdownRemark(fileAbsolutePath: {regex: "/aboutus.md/"}) {
    html
    frontmatter {
      title
    }
  }
}
`
export default AboutPage

